import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * An `Accordion` that expands if there are less than 4 items
 * in the children list, or if the search field is not empty.
 * Minimizes when the search field is cleared.
 *
 * @param {String} title The title of the accordion.
 * @param {Number} [count] The number of items in the children list.
 * @param {React.ReactNode} children The items to display in the accordion.
 * @returns {React.ReactElement}
 */
const AccordionFilter = ({title, count, children}) => {
    const [expanded, setExpanded] = React.useState(count ? count < 4 : true);

    return (
        <Accordion
            sx={{border: 'none'}}
            expanded={expanded}
            elevation={0}
            onChange={(e) => {
                const {target} = e;
                if (target['id'].indexOf('search') > -1) {
                    // Expand if searching.
                    setExpanded(target['id'].value !== '');
                } else if (target['id'].indexOf('clear') > -1) {
                    // Minimize if searchfield is cleared.
                    setExpanded(false);
                } else {
                    setExpanded((e) => !e)
                }
            }}
            disablegutters={"true"}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant={"subtitle2"} component="h2" sx={{minWidth: '100%'}}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                disablegutters={"true"}
                sx={{paddingBottom: 0}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default React.memo(AccordionFilter);
