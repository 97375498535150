import {InputAdornment, TextField} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";

/**
 * Input field used on the primary search page.
 * @param classes
 * @param searchOnEnterCallback
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchInput = ({searchOnEnterCallback, label, museumMenu}) => {
    const [inputValue, setInputValue] = useState('');

    const handleSearchInput = event => {
        setInputValue(event.target.value);
    };

    return <Box sx={(theme) => ({
        [theme.breakpoints.up("xs")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '60%'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '50%'
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: '38rem'
        },
    })}>
        <TextField
            id="dams-search"
            label={label}
            type="search"
            variant="filled"
            onKeyDown={(event) => {
                searchOnEnterCallback(event, inputValue)
            }}
            value={inputValue}
            onChange={handleSearchInput}
            fullWidth
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position={"end"}>
                            {museumMenu}
                        </InputAdornment>
                    )
                }
            }}
        />
    </Box>
};
