import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {clientLog} from "../clientLog";
import {apiCoreObjectToDAMSInput} from "../utility";
import {mapNotMissing} from "../inpututils";

/**
 * Triggered when the user searches for, and selects a person/producer.
 * @param postFn    method  The method used to post data to the DAMS database.
 * @param collectionIds Array
 * @param values    Array
 * @param setNewPersonCallback  method  Adds the new person to the current state object.
 * @param onAddCallback
 * @param onChangeCallback
 */
export const changeHandler = (postFn, collectionIds, values, setNewPersonCallback, onAddCallback, onChangeCallback) => {
    const addingPerson = values.find((value) => value.create);
    const addingExternalPerson = values.find(
        (value) => !value.create && !value.uniqueId
    );
    if (addingPerson) {
        setNewPersonCallback(addingPerson);
    } else if (addingExternalPerson) {
        addingExternalPerson['content']['nameAndTitle'] = addingExternalPerson.title;
        const newPersons = collectionIds.map((collectionId) => ({
            ...addingExternalPerson.content,
            source: addingExternalPerson.source,
            sourceId: addingExternalPerson.sourceId,
            collectionId: collectionId,
        }));
        postFn({persons: newPersons}).then(onAddCallback);
    } else {
        const existingPersons = values
            .map((v) => mapNotMissing(collectionIds, v, "person"))
            .flat();
        Promise.all(values.map(v => postMissing(postFn, collectionIds, v)))
            .then((newPersons) =>
                onChangeCallback([...existingPersons, ...newPersons.flat()])
            );
    }
};

/**
 * Method used to fetch a list of persons matching the given parameters from KulturNav.
 * @param entityType    string  The name of the entity type to search for in KulturNav.
 * @param params    object  An object containing the search parameters.
 * @param locale    string  The locale to use when searching in KulturNav.
 * @returns {Promise<unknown | *[]>}
 */
export const fetchKulturNavData = (entityType, params, locale) => {
    const excludeDatasets = window._env_.REACT_APP_PERSONS_EXCLUDE_DATASETS;
    const excludeDatasetsClause = excludeDatasets.map(ed => `entity.dataset:!${ed}`).join(',');
    const removeDeprecatedKey = 'superconcept.status:853a2d84-5b2b-11e2-bcfd-0800200c9a66';
    const url = `entityType:${entityType},nativeCompoundName:${params},${removeDeprecatedKey},${excludeDatasetsClause}?properties=entity.dataset,entity.fullCaption,person.birth,person.death&displayValues=true&lang=${locale}`;
    return kulturnavApiCoreFetch(url).then(json => {
        const mapped = json.entities.map(j => apiCoreObjectToDAMSInput(j));
        return Promise.resolve(mapped);
    }).catch(error => {
        clientLog('error', error, 'InputPersons');
        return Promise.resolve([]);
    });
};

/**
 * Method used to add persons to the DAMS database.
 * @param postFn    method  The method used to post the data to DAMS.
 * @param collectionIds Array
 * @param value Object  The person
 * @returns {*}
 */
export const postMissing = (postFn, collectionIds, value) => {
    const persons = collectionIds
        .filter((collectionId) => !value.collectionId.includes(collectionId))
        .filter(() => !value.id && !value.uniqueId)
        .map((collectionId) => {
            return {
                ...value.content,
                source: value.source,
                sourceId: value.sourceId,
                collectionId: collectionId,
            }
        });
    return postFn({persons: persons});
};

