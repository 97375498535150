import React from "react";
import styled from "styled-components";
import {CardHeader} from "@mui/material";

export const EllipsisCardHeader = styled(({...other}) => (
    <CardHeader {...other} sx={{
        padding: '.5rem .75rem',
        '& .MuiCardHeader-content': {
            overflow: 'hidden',
        },
    }}/>
))`
    & .MuiCardHeader-content {
        flex: 1 1 auto;
        width: 85%;
    }
`;
