import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import AccordionFilter from "../AccordionFilter";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {useLocation} from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";

/**
 * Filter appearing only under the list of projects,
 * allowing the user to filter the list contents based on the project type.
 * @param callback
 * @returns {Element}
 * @constructor
 */
export const FilterProjectType = ({callback}) => {
    const location = useLocation();
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();

    const projectTypeKey = "document_type";

    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);

    const projectTypes = results.facets.documentTypes;

    const count = Object.keys(projectTypes).length;

    const translatedProjectTypes = {
        'ProjectGeneral': t('projectGeneral', 'Generelt'),
        'ProjectArcheology': t('projectArcheology', 'Arkeologi')
    };

    /**
     * Gets the label used when displaying the filter choices.
     * @param key
     * @returns {*}
     */
    const getLabel = key => {
        return translatedProjectTypes[key];
    };

    /**
     * Modifies the set filter value, according to the changes made by the user.
     * @param value
     * @returns {(function(): void)|*}
     */
    const changeHandler = (value) => () => {
        modifySelectedFilter(value);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === projectTypeKey
                    )
                ) {
                    selectedFilters.push({key: projectTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === projectTypeKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === projectTypeKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: projectTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(projectTypeKey, selectedFilters);
        },
        [callback, selected]
    );

    /**
     * Retrieves the count of active projects for a given project type.
     *
     * @param {string} projectType - The type of project ('ProjectGeneral' or 'ProjectArcheology').
     * @return {number} The count of active projects for the specified project type.
     */
    const getProjectTypeCount = (projectType) => {
        const {facets} = results;
        const key = projectType === 'ProjectGeneral' ? 'generalprojectrefStats' : 'archeologyprojectrefStats';
        const active = facets[key]?.true?.count || 0;
        const inactive = facets[key]?.false?.count || 0;
        return active + inactive;
    };

    /**
     * Hook used to clear all filters.
     */
    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(projectTypeKey, []);
        }
    }, [clearFilters, callback]);

    /**
     * Hook used to add filters from the existing query, if filters are already active.
     */
    useDeepCompareEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === projectTypeKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        }
    }, [fq]);

    /**
     * Hook used to decide whether to display this filter or not.
     */
    useEffect(() => {
        setShow(location.pathname === '/all-projects/' && count);
    }, [location.pathname, count]);

    if (show) {
        return (
            <>
                <AccordionFilter
                    title={`${t("filterProjectHeader", "Prosjekt")} (${count})`}
                    count={count}
                >
                    <List>
                        {Object.keys(projectTypes).map((value) => (
                            <ListItem
                                key={value}
                                dense={true}
                                disablegutters={"true"}
                                onClick={changeHandler(value)}
                            >
                                <ListItemIcon>
                                    <FilterCheckbox
                                        selected={selected}
                                        subjectsKey={projectTypeKey}
                                        value={value}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`label-${value}`}>
                                    {/*{getLabel(value)} ({projectTypes[value].count})*/}
                                    {getLabel(value)} ({getProjectTypeCount(value)})
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </AccordionFilter>
            </>
        );
    } else {
        return <></>;
    }
};
