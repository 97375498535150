import {Dialog, DialogActions, DialogContent, Stack, Switch} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {CLOSE_AB_TEST_DIALOG, useAppDispatch, useAppState, useAppTranslation} from "./AppContext";


export const ABTestDialog = ({callback}) => {

    const t = useAppTranslation();
    const storageKey = 'dams.abtest.features';

    const [open, setOpen] = useState(false);
    const [prefs, setPrefs] = useState({});

    const appDispatch = useAppDispatch();
    const {openAbTestDialog} = useAppState();

    const loadPrefs = async () => {
        const saved = await JSON.parse(localStorage.getItem(storageKey));
        if (saved) {
            setPrefs(saved);
        }
    };

    const savePrefs = async (name, checked) => {
        localStorage.setItem(storageKey, JSON.stringify({...prefs, [name]: checked}));
    };

    const handleChange = event => {
        const {target} = event;
        const {name, checked} = target
        if (!name) {
            return;
        }
        (async () => {
            await savePrefs(name, checked);
            await loadPrefs();
        })();
    };

    const handleCancelClick = () => {
        appDispatch({type: CLOSE_AB_TEST_DIALOG});
    };

    const handleContinueClick = () => {
        setOpen(false);
        if (callback && typeof (callback) == 'function') {
            callback();
        }
    };

    useEffect(() => {
        (async () => loadPrefs())();
    }, []);

    useEffect(() => {
        setOpen(openAbTestDialog);
    }, [openAbTestDialog]);

    return <Dialog open={open}>
        <DialogTitle>
            {t('abTestDialogTitle', 'Vil du prøve nye funksjoner?')}
        </DialogTitle>
        <DialogContent>
            <Box>
                {t('abTestDialogInformation',
                    'Vi jobber hele tiden med nye funksjoner som skal gjøre DAMS enklere, ' +
                    'og smartere, å bruke. Her er noen utvalgte nyheter du kan prøve ut allerede nå. ' +
                    'Du kan når som helst slå funksjonene av eller på igjen senere fra hovedmenyen.')}
            </Box>
            <Box sx={(theme) => ({
                marginTop: '16px',
                border: 'solid 1px #ddd',
                borderRadius: '5px',
                padding: '8px',
                backgroundColor: theme.palette.primary.light
            })}>
                <Typography variant={"subtitle1"} fontWeight={'fontWeightMedium'}>
                    {t('abTestUploadTitle', 'Ny opplasting')}
                </Typography>
                <Stack direction={"row"}>
                    <Typography>
                        {t('abTestUploadInformation',
                            'Beta-versjon av ny opplaster, der prosessering av filer ' +
                            'etter opplasting kjører i bakgrunnen.')}
                    </Typography>
                    <Switch name={"upload"} checked={prefs['upload']} color={"primary"}
                            onChange={handleChange}/>
                </Stack>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button color={"secondary"} onClick={handleCancelClick}>
                {t('btnCancel', 'Avbryt')}
            </Button>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleContinueClick}>
                {t('btnContinue', 'Fortsett')}
            </Button>
        </DialogActions>
    </Dialog>
};