/**
 * Checks if the user has admin-role in the specified museum.
 *
 * @param {{userData: object, museumId: number|array}} props
 * @param {object} props.userData - The user data as provided by the `useAuthsState` hook.
 * @param {number|array} props.museumId - The ID of the museum to check admin-status for.
 * If an array of museum IDs is provided, and the number of items in the array is more than one,
 * the function will return false, as we're unable to determine admin-status in this case.
 *
 * @returns {boolean} true if the user has admin-role in the specified museum, false otherwise.
 *
 * @throws Error if either `userData` or `museumId` is not specified.
 */
export const isAdminOfMuseum = ({userData, museumId}) => {
    if (!userData) {
        throw new Error('userData is not specified');
    }

    if (!museumId) {
        throw new Error('museumId is not specified');
    }

    let _museumId;

    if (Array.isArray(museumId)) {
        // HACK: in some situations the value passed along to this hook is an array of museum ids,
        // If this is the case, and the number of items are more than one, return false, as we're unable
        // to determine admin-status, otherwise proceed, and, evaluate the single value provided.
        if (museumId.length > 1) {
            return false;
        } else {
            _museumId = museumId[0];
        }
    } else {
        _museumId = museumId;
    }

    const getMuseum = _ => {
        return userData['appAccess']
            && userData['appAccess'].museums.find(m => {
                return m.id === _museumId;
            });
    }

    const museum = getMuseum();
    if (!museum) {
        return false;
    }

    const admin = museum['applications']
        .find(app => {
            return window._env_.REACT_APP_ID === app.id
                && app['role']['name'] === 'Admin';
        });

    return typeof (admin) !== 'undefined';
};