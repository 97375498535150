import {useSearchState, useSearchTranslation} from "../SearchContext";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import AccordionFilter from "../AccordionFilter";
import AccordionFilterTitle from "./AccordionFilterTitle";
import SearchFilterCheckboxesList from "./SearchFilterCheckboxesList";

/**
 * Renders the FilterReferenceType component with a list of checkboxes.
 * The list is based on the current search results, and the user can filter the list by typing in a search criteria.
 * The component also handles the selection of filters, and updates the selected filters in the SearchContext.
 *
 * @param {function} callback - The callback function to handle filter changes.
 * @return {JSX.Element} CheckboxFilter component with specified props.
 */
export const FilterReferenceType = ({callback}) => {

    const referenceTypeKey = "reference_types.ref_type.title";

    const t = useSearchTranslation();

    const {fq, results, clearFilters} = useSearchState();

    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState({});
    const [selected, setSelected] = useState([]);

    /**
     * Returns a function that modifies the selected filter state.
     *
     * If the filter is not already present in the selected filter state, add it.
     * If the filter is already present, update its value.
     *
     * If the filter was already present in the previous search, and the new value
     * is *, replace the value with the value from the previous search.
     *
     * @param {string} value The new value of the filter.
     * @returns {function} A function that modifies the selected filter state.
     */
    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === referenceTypeKey
                    )
                ) {
                    selectedFilters.push({key: referenceTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (selectedFilters.find((s) => s.value === value && s.key === referenceTypeKey)) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === referenceTypeKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: referenceTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            if (!fromFq) {
                callback(referenceTypeKey, selectedFilters);
            }
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters && selected.length > 0) {
            setSearchValue('');
            setSelected([]);
        }
    }, [clearFilters, callback, selected.length]);

    useEffect(() => {
        if (fq && !clearFilters) {
            fq.filter((f) => f.key === referenceTypeKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            })
        }
    }, [fq, clearFilters, modifySelectedFilter]);

    const referenceTypes = useMemo(() => results.facets.referenceTypes || [], [results]);
    const count = Object.keys(referenceTypes).length;

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param {Event} event - The event object, used to get the current search value.
     */
    const searchForFilters = (event) => {
        const value = event.target['value'];
        setSearchValue(value);
    };

    const getOptions = useCallback((data) => {
        const matchingKeys = (searchValue !== '' && searchValue.length >= 2)
            ? Object.keys(data)
                .filter(k => k.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1)
            : Object.keys(data);

        // Get the persons that matches the search criteria.
        const filteredPersons = Object.fromEntries(matchingKeys.map(k => [k, data[k]]));

        // Add selected/checked filter values to the list.
        const selectedKeys = selected.map(s => (s.value));
        const selectedPersons = Object.fromEntries(selectedKeys.map(k => [k, data[k]]));

        const opts = {...filteredPersons, ...selectedPersons};
        setOptions(opts);
    }, [searchValue, selected]);

    useEffect(() => {
        if (referenceTypes.length === 0) {
            return;
        }
        getOptions(referenceTypes);
    }, [getOptions, referenceTypes]);

    if (!count) {
        return <></>;
    } else {
        return (
            <AccordionFilter
                title={
                    <AccordionFilterTitle
                        title={`${t("references", "Referanser")} (${count})`}
                        value={searchValue}
                        label={t('subjectsSearch', 'Søk...')}
                        onChangeCallback={searchForFilters}
                        setSearchValueCallback={() => {
                            setSearchValue('');
                        }}
                    />
                }
                count={count}
            >
                <SearchFilterCheckboxesList
                    checkboxKey={referenceTypeKey}
                    selected={selected}
                    opts={options}
                    callback={changeHandler}
                    limit={100}
                />
            </AccordionFilter>
        );
    }
};