import DialogTitle from "@mui/material/DialogTitle";
import {Dialog, DialogActions, DialogContent, ListItemButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useDocumentTranslation} from "../../documents/documentContext";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {useBatchEditDispatch, useBatchEditState} from "../../documents/batchEditContext";
import {FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG, useAppDispatch, useAppState} from "../../app/AppContext";

/**
 * A dialog for selecting fields to edit in a document.
 *
 * @param {{open: boolean}} props - The properties of the dialog.
 * @param {boolean} props.callback - Callback method triggered when the user clicks the continue button.
 *
 * @return {JSX.Element} The rendered dialog.
 */
export const DialogSelectFields = ({callback}) => {

    const {openSelectFieldsDialog} = useAppState();
    const {availableFields, selectedFields} = useBatchEditState();

    const [openDialog, seOpenDialog] = useState(false);
    const [checked, setChecked] = useState([]);

    const appDispatch = useAppDispatch();
    const batchEditDispatch = useBatchEditDispatch();

    const t = useDocumentTranslation();

    const fieldsNotSelected = availableFields.filter(
        (availableField) => !selectedFields.includes(availableField)
    );

    const closeDialog = () => {
        appDispatch({
            type: FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG,
            open: false,
        });
    };

    /**
     * Handles a change to a checkbox in the select field(s) dialog.
     * If the field is already checked, it will be unchecked.
     * If the field is unchecked, it will be checked.
     * @param {string} value The value of the field to be checked or unchecked.
     */
    const handleCheck = (value) => {
        if (checked.includes(value)) {
            setChecked(checked.filter((c) => c !== value));
        } else {
            setChecked([...checked, value]);
        }
    };

    /**
     * Handles a click to the cancel button in the select fields dialog.
     * Resets the state of the selected fields and closes the dialog.
     */
    const handleCancelClick = () => {
        setChecked([]);
        closeDialog();
        callback(undefined);
    };

    /**
     * Handles a click to the continue button in the select field(s) dialog.
     */
    const handleContinueClick = () => {
        closeDialog();
        const selectedFields = [...checked];
        setChecked([]);                             // Unselect fields after they have been selected.
        callback(selectedFields);
    };

    /**
     * Render a list of checkboxes for selecting fields to edit.
     *
     * @param {array} items - The fields to render.
     * @return {JSX.Element} The rendered list.
     */
    const fieldsList = (items) => (
        <List dense component="div" role="list">
            {items.map((item) => {
                const labelId = `transfer-list-item-${item.name}-label`;

                return (
                    <ListItemButton
                        key={item.name}
                        role="listitem"
                        onClick={() => handleCheck(item)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(item) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{"aria-labelledby": labelId}}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={t(item.name)}/>
                    </ListItemButton>
                );
            })}
            <ListItem/>
        </List>
    );

    useEffect(() => {
        seOpenDialog(openSelectFieldsDialog);
    }, [openSelectFieldsDialog]);

    return <Dialog open={openDialog}>
        <DialogTitle>
            {t('dialogEditMetadataChooseFieldsTitle', 'Redigere metadata - Velg felter')}
        </DialogTitle>
        <DialogContent>
            <Typography variant={"caption"}>
                {t('dialogEditMetadataChooseFieldsText', 'Velg de feltene du ønsker å redigere innholdet for.')}
            </Typography>
            {fieldsList(fieldsNotSelected)}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancelClick}>
                {t('btnCancel', 'Avbryt')}
            </Button>
            <Button onClick={handleContinueClick}
                    variant={"contained"}
                    color={"secondary"}>
                {t('btnContinue', 'Fortsett')}
            </Button>
        </DialogActions>
    </Dialog>;
};