import React, {useCallback, useEffect, useState} from "react";
import {
    SET_DOCUMENT_TYPES,
    useDocumentTypeDispatch,
    useDocumentTypeTranslation
} from "../../document-type/documentTypeContext";
import {useProjectState} from "../../project/projectContext";
import {useLocation} from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Typography} from "@mui/material";
import {useSearchState} from "../SearchContext";
import {useMuseumState} from "../../museum/MuseumContext";

export const FilterDocumentTypes = ({callback}) => {

    const {hasArcheologyModule} = useProjectState();

    const location = useLocation();

    const t = useDocumentTypeTranslation();
    const documentTypeDispatch = useDocumentTypeDispatch();
    const {searching, clearFilters} = useSearchState();
    const {museums} = useMuseumState();

    const inProjectsListView = location.pathname === '/all-projects/';
    const inProjectView = location.pathname.indexOf('/project/') > -1;

    const [options, setOptions] = useState([]);
    const [checked, setChecked] = useState([]);

    const baseOptions = [
        {name: 'images', label: t('images', 'Bilder')},
        {name: 'audio', label: t('audio', 'Lyd')},
        {name: 'video', label: t('video', 'Video')},
        {name: 'documents', label: t('misc', 'Annet')},
        {name: 'model', label: t('model', 'Modell')},
    ];

    const projectArcheologyOptions = [
        {name: 'projectGeodata', label: t('geodata', 'Geodata')},
        {name: 'projectTable', label: t('table', 'Tabell')},
    ];

    const checkAllByDefault = useCallback(() => {
        setChecked([...options].map(o => o.name));
    }, [options]);

    const handleChange = event => {
        const {name, checked: checkedItem} = event.target;
        let checkedItems = [...checked];
        const exists = checkedItems.includes(name);
        if (!exists && checkedItem) {
            checkedItems.push(name);
        } else if (exists && !checkedItem) {
            const ix = checkedItems.findIndex(c => c === name);
            checkedItems = checkedItems.toSpliced(ix, 1);
        }
        setChecked(checkedItems);
        callback(true);
    };

    /**
     * Hook used to determine the list of available options/document types.
     */
    useEffect(() => {
        let opts = [...baseOptions];

        if (hasArcheologyModule) {
            if (inProjectsListView) {
                opts = [];
            } else if (inProjectView) {
                opts = [...opts, ...projectArcheologyOptions];
            } else if (!inProjectsListView && !inProjectView) {
                opts = [...opts, ...projectArcheologyOptions];
            }
        }

        setOptions(opts.toSorted((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        }));

        // Make the new list of options "checked".
        // NOTE: Do not call checkAllByDefault here, as this results in a race-condition,
        // and items not being checked!
        setChecked([...opts].map(o => o.name));
    }, [hasArcheologyModule, inProjectsListView, inProjectView, museums]);

    /**
     * Hook used to trigger callback method when the user selects/deselects an option.
     */
    useEffect(() => {
        if (checked.length === 0) {
            checkAllByDefault();
        }

        documentTypeDispatch({type: SET_DOCUMENT_TYPES, items: checked});
    }, [checked, checkAllByDefault, documentTypeDispatch])

    /**
     * Hook used to "clear" the filter, thus setting all checked
     */
    useEffect(() => {
        if (!searching && clearFilters) {
            checkAllByDefault();
        }
    }, [clearFilters, checkAllByDefault, searching]);

    return <FormGroup row>
        {
            options.map(o => (
                <FormControlLabel key={o.name}
                                  disabled={inProjectsListView}
                                  checked={checked.includes(o.name)}
                                  control={<Checkbox size="small" sx={{"&.MuiCheckbox-root": {paddingRight: '.375rem'}}}
                                                     name={o.name}/>}
                                  label={
                                      o.name === 'all'
                                          ? <Typography variant={"body1"}
                                                        sx={{fontWeight: 'bold'}}>{o.label}</Typography>
                                          : <Typography variant={"body1"}>{o.label}</Typography>
                                  }
                                  onChange={handleChange}/>
            ))
        }
    </FormGroup>;
};