import React, {useCallback} from "react";
import {format} from "../../app/dateFormat";
import {
    SHOW,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
} from "../../search-result-details/SearchResultDetailsContext";
import {SELECT, UNSELECT, useSearchDispatch, useSearchState} from "../SearchContext";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import {EllipsisCardHeader} from "../EllipsisCardHeader";
import {ResultCardThumb} from "./ResultCardThumb";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import {Stack} from "@mui/material";


/**
 * A card component for rendering search results.
 *
 * @param {Object} props - The properties for the component.
 * @param {Object} props.model - The model containing the data for the card.
 * @param {boolean} [props.selected=false] - Whether the card should be rendered as selected.
 * @return {JSX.Element} The rendered card component.
 */
const ResultCard = ({model, selected = false}) => {
    const {showInstantly} = useSearchState();
    const {selectedId} = useSearchResultDetailsState();
    const dispatch = useSearchResultDetailsDispatch();
    const searchDispatch = useSearchDispatch();
    const navigate = useNavigate();

    const {folderType: projectType, uniqueId, id, title, documentType, createdAt, createdByName, status} = model;

    /**
     * Click handler for the result card.
     * If the card is a folder, navigate to the project page.
     * Otherwise, dispatch a SHOW action to show the result details.
     */
    const clickHandler = useCallback(() => {
        if (documentType === "Folder") {
            navigate("/project/" + id);
        } else {
            dispatch({
                type: SHOW,
                model: model,
            });
        }
    }, [dispatch, documentType, id, model, navigate]);

    /**
     * Click handler for the checkbox.
     * Dispatches a SELECT or UNSELECT action based on the current selection state.
     */
    const checkHandler = () => {
        searchDispatch({
            type: selected ? UNSELECT : SELECT,
            model: model,
        });
    };

    return (
        <Card sx={{border: 'solid 1px #ddd'}}
              raised={selectedId === uniqueId || selectedId === showInstantly}>
            <EllipsisCardHeader
                // className={classes.cardHeader}
                title={title}
                titleTypographyProps={{noWrap: true, variant: "subtitle2"}}
                action={
                    status !== "project" && (
                        <Checkbox
                            color={"secondary"}
                            checked={selected}
                            onChange={checkHandler}
                        />
                    )
                }
            />

            <CardActionArea onClick={clickHandler}>
                <ResultCardThumb
                    model={model}
                    documentType={documentType}
                    clickHandler={clickHandler}
                />
                {
                    projectType === 'ProjectArcheology' &&
                    <Box sx={{position: 'relative', width: 0, height: 0}}>
                        <HandymanOutlinedIcon sx={{
                            position: 'absolute',
                            top: '-30px',
                            left: '5px',
                            color: '#bbb',
                            width: '25px',
                            height: '25px'
                        }}/>
                    </Box>
                }
                <CardContent>
                    <Stack direction={"column"}>
                        <Stack direction={"row"}
                               justifyContent={"flex-start"}
                               spacing={1}
                               sx={{whiteSpace: 'nowrap'}}>
                            <EventIcon color={"secondary"} sx={{
                                fontSize: 16,
                                verticalAlign: "middle",
                            }}/>
                            <Typography variant={"caption"} color={"secondary"}>
                                {format(createdAt, "P")}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"}
                               justifyContent={"flex-start"}
                               spacing={1}
                               sx={{
                                   whiteSpace: "nowrap",
                                   textOverflow: "ellipsis",
                                   overflow: "hidden",
                                   width: "95%",
                                   maxWidth: "95%",
                               }}>
                            <PersonIcon color={"secondary"} sx={{
                                fontSize: 16,
                                marginRight: 4,
                            }}/>
                            <Typography variant={"caption"} color={"secondary"} noWrap={true}>
                                {createdByName}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default React.memo(ResultCard);