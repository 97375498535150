// {
//     "uniqueId": "3e2da832-018e-49a7-92d3-65a3bdf73322",
//     "createdAt": "2024-09-27 12:34:23.765214",
//     "updatedAt": "2024-09-27 12:35:09.540103",
//     "id": 73,
//     "schemaId": 1,
//     "documentType": "StillImage",
//     "title": "brad-starkey-fcSPN9Uo7yI-unsplash_ff8123f6-0e6d-4672-bfde-22298b8de23e_c8af9a3f-a6f9-4635-b4b9-c355043cda12.jpg",
//     "description": "0638YQH4TLTZ.jpg",
//     "locale": "no",
//     "content": {
//     "mediae": [
//         {
//             "reference": {
//                 "id": 72,
//                 "title": "brad-starkey-fcSPN9Uo7yI-unsplash_ff8123f6-0e6d-4672-bfde-22298b8de23e_c8af9a3f-a6f9-4635-b4b9-c355043cda12.jpg",
//                 "locale": "no",
//                 "source": "dms",
//                 "status": "published",
//                 "mimeType": "image/jpeg",
//                 "schemaId": 1,
//                 "sourceId": "0638YQH4TLTZ",
//                 "uniqueId": "c3b0f006-7cb0-4e28-81e9-323e93fed5e2",
//                 "createdAt": "2024-09-27T12:34:23",
//                 "updatedAt": "2024-09-27T12:34:23",
//                 "documentType": "media",
//                 "fileExtension": "jpg"
//             },
//             "referenceType": "media"
//         }
//     ],
//     "subjects": [],
//     "languages": [],
//     "customIdentifier": ""
// },
//     "status": "published",
//     "collectionId": 3,
//     "createdById": 2,
//     "updatedById": 2,
//     "referenceCount": 0,
//     "parent": null,
//     "updatedByName": "Jonas Andreas Olstad",
//     "createdByName": "Jonas Andreas Olstad"
// }


import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {DialogSelectFields} from "./DialogSelectFields";
import {DocumentProvider} from "../../documents/documentContext";
import {
    FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG,
    FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG,
    useAppDispatch,
    useAppState,
    useAppTranslation
} from "../../app/AppContext";
import {DialogMetadataForm} from "./DialogMetadataForm";
import {SnackbarProvider} from "../../snackbar/SnackbarContext";
import {CopyrightProvider} from "../../copyright/copyrightContext";
import {
    BatchEditProvider,
    SELECT_FIELDS,
    UNSELECT_ALL_FIELDS,
    useBatchEditDispatch
} from "../../documents/batchEditContext";
import {damsFetch} from "../../app/damsFetch";
import {
    getDocumentIconDetails,
    getPersons,
    getPlaces,
    getSubjects,
    renderCopyrightCell,
    renderCopyrightClauseCell,
    renderDocumentTypeCell,
    renderLanguageCell,
    renderLicenseCell,
    renderPersonCell,
    renderPlaceCell,
    renderSubjectCell
} from "./operationFilesTableHelpers";
import {getHrDateAndTime} from "../../utility";
import {format} from "../../app/dateFormat";
import {decamelize} from "../../app/decamelize";
import {getTableTranslations} from "../../table-base/getTableTranslations";
import {useQuery, useQueryClient} from "@tanstack/react-query";


/**
 * A component that displays a table with a list of files for a given operation
 * in the file upload feature.
 *
 * The component uses the Material React Table library to render the table.
 * It also uses the useQuery hook from the react-query library to fetch the
 * list of files from the server. The list of files is filtered based on the
 * operation ID that is passed as a prop.
 *
 * The component also renders a button above the table that allows the user
 * to select which fields to edit in the files. When the user clicks on the
 * button, a dialog is opened where the user can select the fields to edit.
 * The selected fields are stored in the batch edit context.
 *
 * When the user has selected fields to edit, the component renders a form
 * below the table where the user can edit the selected fields for all the
 * selected files at once.
 *
 * The component also renders a button below the table that allows the user
 * to save the edited fields to the server.
 *
 * @param {object} props - The component props.
 * @param {string} props.operationId - The ID of the operation for which to
 * display the list of files.
 * @param {string[]} props.failedConversion - The list of DMS IDs that failed
 * conversion.
 */
export const OperationFilesTable = ({operationId, failedConversion}) => {

    const t = useAppTranslation();
    const appDispatch = useAppDispatch();
    const batchEditDispatch = useBatchEditDispatch();

    const {fileUploadOperationEditFiles} = useAppState();
    const [selectedRows, setSelectedRows] = useState({});

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100
    });

    const {
        data: {data = [], meta} = {},
        isLoading,
        isError,
        isRefetching,
        refetch
    } = useQuery({
        queryKey: ['operation-files',
            {
                pagination,
                // columnFilters,
                //     globalFilter,
                //sorting
            },
        ],
        queryFn: async () => loadDocuments(pagination.pageIndex * pagination.pageSize, pagination.pageSize),
        //placeholderData: keepPreviousData
    });


    const columns = useMemo(() => [{
        accessorKey: 'uniqueId',
        header: t('damsId', 'DAMS ID'),
        enableSorting: false,
        enableHiding: true
    }, {
        header: t('documentType', 'Dokumenttype'),
        accessorFn: row => getDocumentIconDetails(row),
        Cell: ({renderedCellValue}) => renderDocumentTypeCell(renderedCellValue),
        enableSorting: true,
        enableHiding: false
    }, {
        accessorKey: 'title',
        header: t('title', 'Tittel'),
        enableSorting: true,
        enableHiding: false
    }, {
        accessorKey: 'description',
        header: t('description', 'Beskrivelse'),
        enableSorting: false,
    }, {
        accessorKey: 'content.customIdentifier',
        header: t('museumIdentifier', 'Museumsnummer'),
        enableSorting: false,
        enableHiding: true
    }, {
        accessorKey: 'productionDate',
        header: t('productionDate', 'Produksjonsdato'),
        enableSorting: true,
        enableHiding: true,
        accessorFn: row => {
            return format(row.content.productionDate, 'P')
        },
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }, {
        accessorKey: 'producer',
        accessorFn: row => getPersons(row, 'producer'),
        Cell: ({renderedCellValue}) => renderPersonCell(renderedCellValue),
        header: t('producer', 'Fotograf/Produsent'),
        enableSorting: false
    }, {
        accessorKey: 'persons',
        accessorFn: row => getPersons(row, 'persons'),
        Cell: ({renderedCellValue}) => renderPersonCell(renderedCellValue),
        header: t('persons', 'Personer'),
        enableSorting: false
    }, {
        accessorKey: 'places',
        accessorFn: row => getPlaces(row),
        Cell: ({renderedCellValue}) => renderPlaceCell(renderedCellValue),
        header: t('places', 'Steder'),
        enableSorting: false,
    }, {
        accessorKey: 'subjects',
        accessorFn: row => getSubjects(row),
        Cell: ({renderedCellValue}) => renderSubjectCell(renderedCellValue),
        header: t('subjects', 'Emneord'),
        enableSorting: false
    }, {
        accessorKey: 'content.remarks',
        header: t('comments', 'Kommentarer'),
        enableSorting: false,
        enableHiding: true
    }, {
        accessorKey: 'content.languages',
        header: t('languages', 'Språk'),
        enableSorting: false,
        enableHiding: true,
        Cell: ({renderedCellValue}) => renderLanguageCell(renderedCellValue),
    }, {
        accessorKey: 'copyright',
        header: t('copyright', 'Opphavsrett'),
        enableSorting: false,
        enableHiding: true,
        accessorFn: (row) => renderCopyrightCell(t, row)
    }, {
        accessorKey: 'licenses',
        header: t('licenses', 'Lisenser'),
        enableSorting: false,
        enableHiding: true,
        accessorFn: row => renderLicenseCell(t, row)
    }, {
        accessorKey: 'copyrightClause',
        header: t('clauses', 'Klausuler'),
        enableSorting: false,
        enableHiding: true,
        accessorFn: row => renderCopyrightClauseCell(t, row)
    }, {
        accessorKey: 'createdByName',
        header: t('createdBy', 'Opprettet av'),
        enableSorting: true
    }, {
        accessorKey: 'createdAt',
        header: t('createdAt', 'Opplastet dato'),
        enableSorting: true,
        enableHiding: false,
        accessorFn: row => getHrDateAndTime(row.createdAt),
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }, {
        accessorKey: 'updatedAt',
        header: t('updatedAt', 'Sist endret'),
        enableSorting: true,
        accessorFn: row => getHrDateAndTime(row.updatedAt),
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }], [t]);

    const translations = useMemo(() => getTableTranslations(t), [t]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableSelectAll: true,
        enableMultiRowSelection: true,
        enableRowSelection: true,       // enables the checkbox for each row
        enableColumnFilters: false,     // removes the filter option, placed above the table, to the right
        enableColumnOrdering: false,    // removes the drag'n drop column reordering option
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableColumnActions: false,
        selectAllMode: 'all',

        manualPagination: true,
        muiToolbarAlertBannerProps: isError ? {
            color: 'error',
            children: t('errorLoadingData', 'Kunne ikke laste data')
        } : undefined,
        onPaginationChange: setPagination,
        rowCount: meta?.totalRowCount ?? 0,

        state: {
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },

        initialState: {
            density: 'compact',
            sorting: [{id: 'title', desc: false}],
            // pagination: {
            //     pageIndex: 0,
            //     pageSize: 100
            // }
        },
        getRowId: originalRow => originalRow.uniqueId,
        localization: translations,
        muiTablePaperProps: {
            sx: {
                display: 'flex',
                flexDirection: 'column',
            }
        },
    });

    /**
     * @function
     * @param {array} allDocuments - list of documents
     * @param {array} failedDmsIds - list of dms ids that failed conversion
     * @returns {array} - list of documents that survived conversion (i.e. did not have a dms id that failed conversion)
     *
     * This function takes a list of documents and a list of dms ids that failed conversion, and returns a list of documents that did not have a dms id that failed conversion.
     *
     * It does this by filtering through the list of documents, and for each document, it checks if the document has a 'content' property, and if it does, it checks if the 'content' property has a 'mediae' property.
     * If it does, it checks if the 'mediae' property has a 'reference' property, and if it does, it checks if the 'reference' property has a 'sourceId' property.
     * If it does, it checks if the 'sourceId' property is in the list of failedDmsIds. If it is, the document is not included in the returned list.
     *
     * If any of the above checks fail, the document is not included in the returned list.
     *
     * If the list of documents is empty, or if the list of failedDmsIds is empty, the function returns an empty array.
     */
    const getDocumentsThatSurvivedConversion = (allDocuments, failedDmsIds) => {
        if (!allDocuments || allDocuments?.length === 0) {
            return [];
        }

        return allDocuments.filter(d => {
            if (!Object.keys(d).includes('content')) {
                return false;
            }

            const {content} = d;

            if (!Object.keys(content).includes('mediae')) {
                return false;
            }

            const {mediae} = content;

            if (mediae.length === 0) {
                return false;
            }

            if (!Object.keys(mediae[0]).includes('reference')) {
                return false;
            }

            const {reference} = mediae[0];

            if (!Object.keys(reference).includes('sourceId')) {
                return false;
            }

            return !failedDmsIds.includes(reference.sourceId);
        });
    };

    /**
     * Load the list of files for the given operation.
     *
     * This function sends a POST request to the `/jobs/file-upload/get-files` endpoint
     * with the operation ID as parameter. The response is a list of files, which
     * is then set as the table data.
     *
     * @return {Promise<void>} A promise that resolves when the files are loaded.
     */
    const loadDocuments = async (start, pageSize) => {
        const res = await damsFetch('/jobs/file-upload/get-files', {
            method: 'POST',
            body: decamelize(JSON.stringify({
                operationId: operationId,
                start: start,
                rows: pageSize
            }))
        });
        return {
            data: getDocumentsThatSurvivedConversion(res.data, failedConversion),
            meta: {...res.meta}
        };
    };

    /**
     * Handler triggered when the user clicks on the "Rediger metadata" button
     * above the table of files. Opens the dialog where the user can select
     * which fields to edit.
     */
    const handleEditMetadataClick = () => {
        appDispatch({
            type: FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG,
            open: true,
        });
    };

    /**
     * Clears the selection of rows in the table. This is called when the user
     * clicks the "Nullstill" button in the dialog that is opened by clicking
     * on the "Rediger metadata" button above the table of files.
     */
    const clearSelection = () => {
        batchEditDispatch({type: UNSELECT_ALL_FIELDS});   // Clear fields from the edit-form.
        table.resetRowSelection();
    };

    /**
     * Function that is called when the user has selected fields to edit in the files table.
     * It dispatches an action to the app context to show the edit metadata form with
     * the selected fields.
     *
     * @param selectedFields {string[]} The fields that were selected by the user to edit.
     */
    const showEditMetadataForm = (selectedFields) => {
        if (!selectedFields) {
            // User has cancelled the operation, clear selection and quit.
            clearSelection();
            return;
        }

        const selectedRows = table.getState().rowSelection;

        // Batch edit context keeps track of the selected fields.
        batchEditDispatch({
            type: SELECT_FIELDS,
            fields: selectedFields
        });

        appDispatch({
            type: FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG,
            open: true,
            operationId: operationId,
            documentIds: Object.keys(selectedRows),
        });
    };

    const queryClient = useQueryClient();

    /**
     * Called when the user clicks on the "Lagre" button in the edit metadata form.
     * The function resets the selected table rows and should also reload the
     * list of files in the table, but this is currently commented out.
     *
     * @param {string[]} editedDocumentIds The IDs of the documents that were edited.
     */
    const editMetadataCallback = (editedDocumentIds) => {
        clearSelection();
        if (!editedDocumentIds) {
            // User has cancelled the operation, quit.
            return;
        }
        queryClient.invalidateQueries({queryKey: ['operation-files']}).then();
    };

    const rowSelection = table.getState().rowSelection;

    /**
     * Hook used to get the list of selected rows/unique IDs.
     */
    useEffect(() => {
        setSelectedRows(rowSelection);
    }, [rowSelection]);


    return columns && data &&
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}>
                <Button onClick={handleEditMetadataClick}
                        disabled={Object.keys(selectedRows).length === 0}
                        variant={"contained"}
                        sx={{
                            marginBottom: '8px'
                        }}>
                    {t('btnEditMetadata', 'Rediger metadata')}
                </Button>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto'
            }}>
                <MaterialReactTable table={table}/>
            </Box>

            <BatchEditProvider>
                <DialogSelectFields callback={showEditMetadataForm}/>
            </BatchEditProvider>

            {fileUploadOperationEditFiles.open
                && <DocumentProvider>
                    <SnackbarProvider>
                        <CopyrightProvider>
                            <DialogMetadataForm callback={editMetadataCallback}/>
                        </CopyrightProvider>
                    </SnackbarProvider>
                </DocumentProvider>
            }
        </Box>;
};