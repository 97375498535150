import AccordionFilter from "../AccordionFilter";
import {FilterCopyrightType} from "./FilterCopyrightType";
import {FilterLicenses} from "./FilterLicenses";
import React from "react";
import {FilterCopyrightClause} from "./FilterCopyrightClause";
import {useSearchTranslation} from "../SearchContext";

export const AccordionFilterRights = ({callback}) => {
    const t = useSearchTranslation();
    return <AccordionFilter title={t('headingRights', 'Rettigheter')}>
        <FilterCopyrightType callback={callback}/>
        <FilterLicenses callback={callback}/>
        <FilterCopyrightClause callback={callback}/>
    </AccordionFilter>;
};
