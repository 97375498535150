import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import React, {useCallback, useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useAddCopyrightClause} from "./useAddCopyrightClause";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useDocumentState} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import {damsSearch} from "../app/damsFetch";
import {useField, useFormikContext} from "formik";
import {getBrowserLocale} from "../utility";
import {clientLog} from "../clientLog";


/**
 * A component used to search and select copyright clauses from KulturNav and DAMS.
 *
 * @param {Object} t - A translation object.
 * @param {function} copyrightClauseOnChangeHandler - A callback function, used to handle state-changes in the parent component.
 * @param {number} ix - The index of the copyright clause to be edited.
 * @param {string} collectionId - The collection ID to be used for the search.
 * @param {Object} props - Additional props to be passed to the Autocomplete component.
 *
 * @returns {React.ReactElement} - A React element representing the Autocomplete component.
 */
export const FormikSelectCopyrightClause = ({t, copyrightClauseOnChangeHandler, ix, collectionId, ...props}) => {
    const [options, setOptions] = useState([]);
    const saveCopyrightClause = useAddCopyrightClause();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();
    const {values} = useFormikContext();

    const locale = getBrowserLocale()
    const k = `copyrightClause${ix}`;

    const [field] = useField(k);
    const {setFieldValue} = useFormikContext();

    const [loaded, setLoaded] = useState(false);

    // NOTE: When editing data for a project, only one collectionID is selected, and is sent directly to this component.
    const currentCollectionIds = collectionIds.length === 0 ? [collectionId] : collectionIds;

    const museums = museumCollections
        .filter((museumCollection) =>
            currentCollectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    /**
     * Fetches copyright clauses stored in KulturNav.
     * @returns {Promise<unknown>}
     */
    const fetchKulturNavValues = useCallback(async () => {
        try {
            const url = `entity.dataset:e6d2677a-3d2e-4fe5-b9cd-949f9bf5a09a,entityType:Concept?properties=entity.dataset,entity.fullCaption&displayValues=true&lang=${locale}`;
            const res = await kulturnavApiCoreFetch(url);
            const {entities} = res;
            return entities.map(d => ({
                sourceId: d.uuid,
                title: d.properties['entity.fullCaption'][0]['value'][locale]
                    || d.properties['entity.fullCaption'][0]['value']['*'],
                source: d.properties['entity.dataset'][0]['displayValue'][locale]
            }));
        } catch (err) {
            clientLog('error', err.toString(), 'formikselectcopyrightclause')
        }
    }, [locale]);


    /**
     * Fetches copyright clauses stored in the DAMS database.
     * @returns {Promise<import("../app/damsFetch").DAMSModel[]>}
     */
    const fetchDamsValues = useCallback(async () => {
        try {
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: '*',
                    fl: "title,id",
                    sort: "title asc",
                    expand: true,
                    documentType: `("copyrightclause")`,
                    museums: museums.join(","),
                })
            );
            const res = await damsSearch(searchParams);
            const {models} = res;
            return models;
        } catch (err) {
            clientLog('error', err.toString(), 'formikselectcopyrightclause');
        }
    }, [museums]);

    /**
     * Search handler, used to fetch data from both KulturNav and the DAMS database,
     * and merge these values into a single list of options.
     */
    const copyrightClauseSearchHandler = useCallback(async () => {
        const kulturNavValues = await fetchKulturNavValues();
        const damsValues = await fetchDamsValues();
        const opts = kulturNavValues.map(opt => {
            const exists = damsValues.find(d => d.id === opt.sourceId);
            if (exists) {
                opt['collectionId'] = exists.collectionId;
                opt['uniqueId'] = exists.uniqueId;
            }
            return opt;
        });
        setOptions(opts);
        setLoaded(true);
    }, [fetchDamsValues, fetchKulturNavValues]);

    /**
     * Add objects to the DAMS database, if they don't exist.
     * @param value
     * @returns {*}
     */
    const postMissing = (value) => {
        const optionNotInDAMS = options.find(o => {
            return o.sourceId === value.sourceId && !o.collectionId
        });

        if (!optionNotInDAMS) {
            return;
        }

        if (Array.isArray(collectionId)) {
            optionNotInDAMS['collectionId'] = collectionId[0];
        } else {
            optionNotInDAMS['collectionId'] = collectionId;
        }

        return saveCopyrightClause(optionNotInDAMS);
    };

    const onChangeHandler = (_event, option) => {
        if (option) {
            if (option.value) {
                postMissing(option.value);
            }
            setFieldValue(field.name, option.value).then();
            copyrightClauseOnChangeHandler(option.value, ix);
        } else {
            copyrightClauseOnChangeHandler(null, ix);
        }
    };

    const loadedInfoToCopyrightClauseProperty = loadedInfo => {
        return {
            copyrightClause: {
                collectionId: loadedInfo.collectionId,
                source: loadedInfo.source,
                sourceId: loadedInfo.sourceId,
                title: loadedInfo.title,
                uniqueId: loadedInfo.uniqueId || null
            }
        };
    };

    const createLabelObj = (title, value) => {
        return {title: title, value: value};
    }

    const getOptions = () => {
        return options.map(o => (
            {label: o.title, value: o}
        ));
    };

    /**
     * Gets, and sets, the value loaded from DAMS.
     * @returns {string|{label, value}|*}
     */
    const getLoadedValue = () => {
        let v = '';
        let loadedInfo = values['copyrightInfo'];

        if (!loadedInfo) {
            if (!field.value) {
                return v;
            }
            v = createLabelObj(field.value.title, field.value);
        } else {
            // Handle new selection, that has not been previously saved.
            if (loadedInfo?.length === 0 && field.value) {
                v = createLabelObj(field.value.title, field.value);
            } else {
                loadedInfo = loadedInfo[ix]; // Fetch data for the relevant field index only!
                if (!loadedInfo) {
                    return v;
                }

                // Convert loadedInfo object to an object containing the "copyrightClause" property,
                // used to match the object-structure of the options.
                if (!Object.keys(loadedInfo).includes('copyrightClause')) {
                    loadedInfo = loadedInfoToCopyrightClauseProperty(loadedInfo);
                }

                v = {
                    label: loadedInfo.copyrightClause.title,
                    value: loadedInfo.copyrightClause
                };
            }
        }
        return v;
    }

    /**
     * Hook used to fetch the data from KulturNav and DAMS.
     */
    useEffect(() => {
        if (loaded) {
            return;
        }
        (async () => copyrightClauseSearchHandler())();
    }, [copyrightClauseOnChangeHandler, loaded, copyrightClauseSearchHandler]);

    return <Autocomplete
        fullWidth
        key={k}
        renderInput={(params) => (
            <TextField
                {...params}
            />
        )}
        loading={!loaded}
        loadingText={t('autoCompleteLoading', 'Laster...')}
        options={getOptions()}
        onChange={onChangeHandler}
        value={getLoadedValue()}
        {...props}
    />;
};