import {VolumeUpOutlined} from "@mui/icons-material";
import {DamsFileIcon} from "../../files/DamsFileIcon";
import React from "react";
import Box from "@mui/material/Box";
import {useStyles} from "./resultCardStyles";

/**
 * Renders an audio icon for a result card.
 *
 * @param {Object} props - The component props.
 * @param {string} props.mimeType - The MIME type of the audio file.
 * @param {Object} props.model - The model object containing the audio file information.
 * @param {boolean} [props.icon=true] - Whether to display the icon.
 * @return {JSX.Element} The rendered audio icon component.
 */
export const ResultCardAudioIcon = ({mimeType, model, icon = true}) => {
    const classes = useStyles();
    return (
        <Box>
            <div className={classes['fileThumbContainer']}>
                <VolumeUpOutlined sx={{fontSize: 135, color: "#bbb"}}/>
            </div>
            <div className={classes['fileTypeContainer']}>
                {icon && <DamsFileIcon
                    mimeType={mimeType}
                    filename={model?.content?.mediae[0]?.reference?.title}
                    size={36}
                />}
            </div>
        </Box>
    );
};
