import React, {useState} from "react";
import {ADD_FQ, REMOVE_FQ, SET_SEARCHING, useSearchDispatch, useSearchTranslation,} from "../SearchContext";
import AccordionFilter from "../AccordionFilter";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const FilterStatus = () => {
    const searchDispatch = useSearchDispatch();
    const t = useSearchTranslation();
    const statusKey = "status";
    const [currentValue, setCurrentValue] = useState("published");

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const setStatus = (value) => {
        searchDispatch({
            type: REMOVE_FQ,
            fq: {
                key: statusKey,
                value: `"${currentValue}"`,
            },
        });
        searchDispatch({
            type: ADD_FQ,
            fq: {
                key: statusKey,
                value: `"${value}"`,
            },
        });
        setCurrentValue(value);

        // Execute search immediately
        searchDispatch({type: SET_SEARCHING});
    };

    return (
        <AccordionFilter title={`${t("status", "Status")}`}>
            <RadioGroup
                aria-label="status"
                name="status"
                value={currentValue}
                onChange={handleChange}
            >
                <FormControlLabel
                    value="published"
                    control={<Radio/>}
                    label={t("txtPublished", "Publisert")}
                />
                <FormControlLabel
                    value="trash"
                    control={<Radio/>}
                    label={t("txtDeleted", "Slettet")}
                />
            </RadioGroup>
        </AccordionFilter>
    );
};
