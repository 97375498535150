import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FilterCheckbox} from "./FilterCheckbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import List from "@mui/material/List";

/**
 * Renders a list of checkboxes based on the provided options, facets, and event handlers.
 *
 * @param {string} checkboxKey - The key used to identify the facet in the facets object.
 * @param {Object} selected - The object containing the currently selected checkbox values.
 * @param {Object} opts - The options object containing the available checkbox values.
 * @param {function} callback - The event handler for checkbox clicks.
 * @param {number} limit - The maximum number of options to display.
 * @return {JSX.Element} An array of JSX elements representing the checkboxes.
 */
const SearchFilterCheckboxesList = ({checkboxKey, selected, opts, callback, limit = null}) => {

    const getOptions = () => {
        let items = [];

        if (limit === null) {
            items = Object.keys(opts);
        } else {
            items = Object.keys(opts).slice(0, limit);
        }

        return items.map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={callback(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={checkboxKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {value} ({opts[value]?.count})
                </ListItemText>
            </ListItem>
        ));
    };

    return <List>{Object.keys(opts).length > 0 && getOptions()}</List>;
};

export default React.memo(SearchFilterCheckboxesList);