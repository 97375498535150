import React, {useCallback, useEffect, useMemo, useState} from "react";
import {If} from "../../app/If";
import {ResultCardImage} from "./ResultCardImage";
import {ResultCardAudioIcon} from "./ResultCardAudioIcon";
import {ResultCardDocumentIcon} from "./ResultCardDocumentIcon";
import {VideoPlayOverlayIcon} from "../../media/VideoPlayOverlayIcon";
import {makeStyles} from "@mui/styles";
import {ArcheologyProjectFileIcon} from "./ArcheologyProjectFileIcon";
import Box from "@mui/material/Box";
import {Tooltip} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {ResultCardProjectIcon} from "./ResultCardProjectIcon";
import {damsProjectTypes} from "../../project/damsProjectTypes";
import {isProjectActive} from "../../project/projectUtility";
import PropTypes from "prop-types";
import {getMimeTypeFromModel} from "../../utility";
import {ResultCardThreeDIcon} from "./ResultCardThreeDIcon";

const useStyles = makeStyles(() => ({
    imageContainer: {
        width: "100%",
        height: 140,
        textAlign: "center",
        position: 'relative'
    },
}));

/**
 * Component responsible for rendering a thumbnail for a given model.
 * The thumbnail will be one of the following types:
 * - MediaCard (if the model is an image, video or pdf)
 * - AudioIcon (if the model is an audio file)
 * - DocumentIcon (if the model is a document)
 * - ProjectIcon (if the model is a project)
 * - ArcheologyProjectFileIcon (if the model is a geodata or tabell)
 * - ThreeDIcon (if the model is a 3d model)
 * The type of thumbnail rendered is decided by the `decideThumbType` function.
 * @param {{model: object, clickHandler: function, icon: boolean}} props
 * @returns {JSX.Element}
 */
const ResultCardThumb = ({model, clickHandler, icon = true}) => {
    const classes = useStyles();
    let mimeType = getMimeTypeFromModel(model);
    if (!mimeType) {
        mimeType = '';
    }

    const {documentType, folderType, folders} = model;

    const [showMediaCard, setShowMediaCard] = useState(false);
    const [showAudioThumb, setShowAudioThumb] = useState(false);
    const [showArcheologyThumb, setShowArcheologyThumb] = useState(false);
    const [partOfProject, setPartOfProject] = useState(undefined);

    const projectActive = model.folderType && isProjectActive(model);

    const showProjectThumb = mimeType === "" && documentType === "Folder" && damsProjectTypes.includes(folderType);

    /**
     * Hook used to see if the current model is part of a project or not.
     */
    useEffect(() => {
        if (!folders || folders.length === 0) {
            return;
        }
        // Is this model related to a project?
        const folder = folders.find((f) => damsProjectTypes.includes(f.type));
        setPartOfProject(folder);
    }, [folders]);

    const decideThumbType = useCallback(() => {
        setShowAudioThumb(mimeType.indexOf("audio") > -1);
        if (
            mimeType.indexOf("image") > -1 ||
            mimeType.indexOf("pdf") > -1 ||
            mimeType.indexOf("video") > -1
        ) {
            setShowMediaCard(true);
        } else {
            setShowMediaCard(false);
        }

        // Override if archeology project document type
        if (
            documentType === "Geodata" ||
            documentType === "Tabell"
        ) {
            setShowAudioThumb(false);
            setShowMediaCard(false);
            setShowArcheologyThumb(true);
        }
    }, [mimeType, documentType]);

    useMemo(() => decideThumbType(), [decideThumbType]);

    return <div className={classes.imageContainer}>
        <If boolean={showMediaCard}>
            <ResultCardImage
                model={model}
                clickHandler={clickHandler}
                icon={icon}
            />
        </If>

        <If boolean={!showMediaCard && showAudioThumb}>
            <ResultCardAudioIcon mimeType={mimeType} model={model} icon={icon}/>
        </If>

        <If
            boolean={
                !showMediaCard &&
                !showAudioThumb &&
                !showArcheologyThumb &&
                !showProjectThumb &&
                "Modell" !== documentType
            }
        >
            <ResultCardDocumentIcon mimeType={mimeType} model={model} icon={icon}/>
        </If>

        <If boolean={showProjectThumb && icon}>
            <ResultCardProjectIcon model={model} active={projectActive}/>
        </If>

        <If boolean={"Video" === documentType && icon}>
            <VideoPlayOverlayIcon/>
        </If>

        <If boolean={"Geodata" === documentType}>
            <ArcheologyProjectFileIcon model={model} mimeType={mimeType} icon={icon}/>
        </If>

        <If boolean={"Tabell" === documentType}>
            <ArcheologyProjectFileIcon model={model} mimeType={mimeType} icon={icon}/>
        </If>

        <If boolean={"Modell" === documentType}>
            <ResultCardThreeDIcon model={model} mimeType={mimeType} icon={icon}/>
        </If>

        {partOfProject && icon && (
            <Box
                sx={{
                    display: "inline",
                    position: "absolute",
                    bottom: 6,
                    left: 6,
                    padding: '2px',
                    backgroundColor: "white",
                    borderRadius: '2px',
                }}
            >
                <Tooltip title={partOfProject?.title}>
                    <AccountTreeIcon sx={{width: '1rem', height: '1rem', color: "#5f5f5f", verticalAlign: "middle"}}/>
                </Tooltip>
            </Box>
        )}
    </div>

};

ResultCardThumb.propTypes = {
    "model": PropTypes.object.isRequired,
    "clickHandler": PropTypes.func.isRequired,
    "showInstantly": PropTypes.string,
    "icon": PropTypes.bool
};

export {ResultCardThumb};
