import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import Typography from "@mui/material/Typography";
import SearchFilterCheckboxesList from "./SearchFilterCheckboxesList";

export const FilterLicenses = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const licensesKey = "licenses.reference.title";
    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;
            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === licensesKey
                    )
                ) {
                    selectedFilters.push({key: licensesKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === licensesKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === licensesKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: licensesKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(licensesKey, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(licensesKey, []);
        }
    }, [clearFilters, callback]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === licensesKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        }
    }, [fq, modifySelectedFilter]);

    const licenses = results.facets.licenses;
    const count = Object.keys(licenses).length;
    if (!count) {
        return null;
    }

    return (
        <>
            <Typography variant={"body2"}>{t('headingLicense', 'Lisens')}</Typography>
            <SearchFilterCheckboxesList
                checkboxKey={licensesKey}
                selected={selected}
                opts={licenses}
                callback={changeHandler}
            />
        </>
    );
};
