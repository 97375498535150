import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import AccordionFilter from "../AccordionFilter";
import SearchFilterCheckboxesList from "./SearchFilterCheckboxesList";

export const FilterLanguages = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const languagesKey = "languages.title";

    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === languagesKey
                    )
                ) {
                    selectedFilters.push({key: languagesKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === languagesKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === languagesKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: languagesKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(languagesKey, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(languagesKey, []);
        }
    }, [clearFilters, callback, languagesKey]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === languagesKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        } else {
            setSelected([]);
        }
    }, [fq, modifySelectedFilter]);

    const languages = results.facets.languages;
    const count = Object.keys(languages).length;
    if (!count) {
        return null;
    }

    return (
        <AccordionFilter
            title={`${t("languages", "Språk")} (${count})`}
            count={count}
        >
            <SearchFilterCheckboxesList
                opts={languages}
                selected={selected}
                callback={changeHandler}
            />
        </AccordionFilter>
    );
};
