import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";

export const SET_ACTIVE_STEP = 'fileUploadContext/activeStep';
export const ADD_COLLECTION_ID = "fileUploadContext/addCollectionId";
export const ADD_FILES = "fileUploadContext/addFiles";
export const ADD_FAILED = "fileUploadContext/addFailed";
export const TOGGLE_SELECTED = 'fileUploadContext/toggleSelected';
export const TOGGLE_ALL_SELECTED = 'fileUploadContext/toggleAllSelected';
export const CLEAR_SELECTED = 'fileUploadContext/clearSelected';
export const SET_FORM_DATA = 'fileUploadContext/setFormData';
export const ADD_SAVED = 'fileUploadContext/addSaved';
export const CLEAR_EDITED_FLAG = 'fileUploadContext/clearEditedFlag';
export const SET_PROJECT = 'fileUploadContext/setProject';
export const TOGGLE_OPEN_CONFIRM_DIALOG = 'fileUploadContext/toggleOpenConfirmDialog';
export const CLOSE_CONFIRM_DIALOG = 'fileUploadContext/closeConfirmDialog';
export const SET_ADDING_TO_FOLDER = 'fileUploadContext/setAddingToFolder';
export const CLEAR_ADDING_TO_FOLDER = 'fileUploadContext/clearAddingToFolder';
export const CLEAR_ALL = 'fileUploadContext/clearAll';
export const ADD_UPLOADED_NOT_PROCESSED = 'fileUploadContext/addUploadedNotProcessed';
export const REMOVE_FILES_NOT_PROCESSED = 'fileUploadContext/removeFilesNotProcessed';

const FileUploadState = createContext(undefined);
const FileUploadDispatchContext = createContext(undefined);
const FileUploadTranslationContext = createContext(undefined);

const initialState = {
    project: undefined,         // Holds the project, if operating in project context.
    activeStep: 0,              // Holds the active step of the upload wizard.
    collectionId: -1,           // Holds the selected Collection ID, when the user has chosen a museum.
    formData: {},               // Holds the metadata form data.
    uploadedFiles: [],          // Holds the uploaded files.
    uploadedFilesNotProcessed: [], // Holds the uploaded files before the are processed (... DAMS-objects created).
    failedFiles: [],            // Holds the list of files that failed to upload.
    selected: [],               // Holds the unique ID's of the selected objects.
    saved: [],                  // Holds object representing the files after saving to DAMS.
    allSelected: false,         // True if all items are selected.
    openConfirmDialog: false,   // True if a confirm dialog is to be shown.
    addingToFolder: false,      // True if in process of adding object(s) to a folder.
    folderId: -1                // Holding the ID that the files are being added to.
};

const fileUploadReducer = (state, action) => {
    switch (action.type) {
        case ADD_COLLECTION_ID:
            return {
                ...state,
                collectionId: action.collectionId
            };
        case SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.activeStep
            };
        case TOGGLE_SELECTED:
            const selectedFiles = [...state.selected];
            const atIndex = selectedFiles.findIndex(f => f === action.uniqueId);
            if (atIndex > -1) {
                selectedFiles.splice(atIndex, 1);
            } else {
                selectedFiles.push(action.uniqueId);
            }
            return {
                ...state,
                selected: selectedFiles
            };
        case TOGGLE_ALL_SELECTED:
            let selectedUniqueIds = [];
            let allChecked = !state.allSelected;
            if (allChecked) {
                selectedUniqueIds = action.uniqueIds;
            }
            return {
                ...state,
                allSelected: allChecked,
                selected: selectedUniqueIds
            };
        case CLEAR_SELECTED:
            return {
                ...state,
                allSelected: false,
                selected: []
            };
        case ADD_FILES:
            const addedFiles = [...new Set([...state.uploadedFiles, ...action.uploadedFiles])];
            return {
                ...state,
                uploadedFiles: addedFiles
            };
        case ADD_FAILED:
            return {
                ...state,
                failedFiles: action.failed
            };
        case ADD_SAVED:
            let existing = [...state.saved];
            action.documents.forEach(f => {
                const existsAtIx = existing.findIndex(e => e.uniqueId === f.uniqueId);
                if (existsAtIx > -1) {
                    existing[existsAtIx] = f;
                } else {
                    existing.push(f);
                }
            });

            return {
                ...state,
                saved: existing
            };
        case CLEAR_EDITED_FLAG:
            return {
                ...state,
                saved: [...state.saved].map(s => ({...s, edited: false}))
            };
        case SET_FORM_DATA:
            return {
                ...state,
                formData: action.formData
            };
        case SET_PROJECT:
            return {
                ...state,
                project: action.project,
                collectionId: action.project?.collectionId
            };
        case TOGGLE_OPEN_CONFIRM_DIALOG:
            return {
                ...state,
                openConfirmDialog: !state.openConfirmDialog,
                folderId: action.folderId || -1
            };
        case CLOSE_CONFIRM_DIALOG:
            return {
                ...state,
                openConfirmDialog: false
            };
        case SET_ADDING_TO_FOLDER:
            return {
                ...state,
                addingToFolder: true,
                folderId: action.folderId
            };
        case CLEAR_ADDING_TO_FOLDER:
            return {
                ...state,
                addingToFolder: false,
                folderId: -1
            }
        case CLEAR_ALL:
            return {
                ...initialState
            };
        case ADD_UPLOADED_NOT_PROCESSED:
            return {
                ...state,
                uploadedFilesNotProcessed: [...state.uploadedFilesNotProcessed, ...action.files]
            };
        case REMOVE_FILES_NOT_PROCESSED:
            // If the user is retrying uploading a file, or adding files to a list of already uploaded files,
            // keep the list of files to process in sync to avoid duplicates.
            const out = mergeToUniqueArray([...state.uploadedFilesNotProcessed], [...action.files]);
            return {
                ...state,
                uploadedFilesNotProcessed: out.filter(o => o.uniqueId)
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};


const mergeToUniqueArray = (array1, array2) => {
    let arrayMerge = array1.concat(array2)
    return arrayMerge.filter((item, index) =>
        arrayMerge.indexOf(item) == index
    );
};


export const FileUploadProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        fileUploadReducer,
        initialState,
        undefined
    );
    const {t} = useTranslation("dams.fileupload", {useSuspense: false});

    return (
        <FileUploadTranslationContext.Provider value={t}>
            <FileUploadState.Provider value={state}>
                <FileUploadDispatchContext.Provider value={dispatch}>
                    {children}
                </FileUploadDispatchContext.Provider>
            </FileUploadState.Provider>
        </FileUploadTranslationContext.Provider>
    );
};

export const useFileUploadState = () => {
    const context = useContext(FileUploadState);
    if (undefined === context) {
        throw new Error(
            "useFileUploadState must be used within an FileUploadProvider."
        );
    } else {
        return context;
    }
};

export const useFileUploadDispatch = () => {
    const context = useContext(FileUploadDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useFileUploadDispatch must be used within an FileUploadProvider."
        );
    } else {
        return context;
    }
};

export const useFileUploadTranslation = () => {
    const context = useContext(FileUploadTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useFileUploadTranslation must be used within an FileUploadProvider"
        );
    } else {
        return context;
    }
};
