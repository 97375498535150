import {getArcheologyProjectsOverride} from "../utility";
import {useAuthsState} from "./authsContext";
import {useCallback, useEffect} from "react";
import {HAS_ARCHEOLOGY_MODULE, useProjectDispatch} from "../project/projectContext";
import {clientLog} from "../clientLog";
import {useMuseumState} from "../museum/MuseumContext";

/**
 * @function useHasArcheologyModule
 * @description
 * Checks if the archeology module is enabled, either by the override flag or by the museum features.
 * It also updates the `hasArcheologyModule` state in the project context.
 * @returns {void}
 */
export const useHasArcheologyModule = () => {
    const override = getArcheologyProjectsOverride();
    const {museumFeatures} = useAuthsState();
    const featureValue = Boolean(museumFeatures.find(m => m.features.archeology === true))
    const projectDispatch = useProjectDispatch();

    const {museums: selectedMuseum} = useMuseumState();

    const getOverrideStatus = useCallback((museums) => {
        let msg;
        if (!override && museums.length === 0) {
            msg = `has archeology module: ${override}`;
            clientLog('info', msg);
            return featureValue;
        } else if (!override && museums.length > 0) {
            msg = `has archeology module: ${override} ${museums[0]}`;
            clientLog('info', msg);
            return Boolean(museumFeatures.find(f => museums.includes(f.id) && f.features.archeology === true));
        } else {
            msg = `has archeology module: ${override} (override)`;
            clientLog('info', msg);
            return override;
        }
    }, [override, featureValue, museumFeatures]);

    useEffect(() => {
        projectDispatch({
            type: HAS_ARCHEOLOGY_MODULE,
            hasArcheologyModule: getOverrideStatus(selectedMuseum)
        });
    }, [
        museumFeatures,
        featureValue,
        override,
        getOverrideStatus,
        projectDispatch,
        selectedMuseum
    ]);
};
