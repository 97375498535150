import React, {useEffect, useMemo, useState} from "react";
import throttle from "lodash/throttle";
import {useDocumentTranslation} from "../documents/documentContext";
import {If} from "../app/If";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {useTheme} from "@emotion/react";

/**
 * A wrapper around `Autocomplete` for searching for licenses in KulturNav.
 *
 * @param {Object} props
 * @param {string} props.id - The HTML id of the component.
 * @param {Function} props.searchHandler - A function that takes a search query and returns a promise that resolves
 * with a list of options.
 * @param {Function} props.onChange - A function that is called when the selected option changes.
 * @param {Function} props.onClear - A function that is called when the user clears the input.
 * @param {boolean} [props.disabled=false] - Whether the component is disabled.
 * @param {string} [props.inputLabel=""] - The label of the input field.
 * @param {React.ElementType} [props.Icon] - An icon to display next to the input field.
 * @param {Object|Object[]} [props.value] - The currently selected value.
 * @param {boolean} [props.multiple=false] - Whether the user can select multiple options.
 * @param {string} [props.size=null] - The size of the input field.
 * @param {string} [props.helperText=null] - The helper text to display below the input field.
 *
 * @returns {React.ReactElement} The rendered component.
 */
export const KulturNavLicenseSearch = ({
                                           id,
                                           searchHandler,
                                           onChange,
                                           onClear,
                                           disabled,
                                           inputLabel,
                                           Icon,
                                           value,
                                           multiple = true,
                                           size = null,
                                           helperText = null,
                                       }) => {
    const theme = useTheme();
    const [inputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [searching, setSearching] = useState(false);
    const t = useDocumentTranslation();

    const [loaded, setLoaded] = useState(false);


    const fetchOptions = useMemo(
        () =>
            throttle(async (request) => {
                setLoaded(false);
                const params = request
                    .split(" ")
                    .map((word) => `${word}*`)
                    .join(" ");
                setOptions([]);
                setSearching(true);

                const data = await searchHandler(params);
                populateOptions(data);
                setLoaded(true);
            }, 400),
        [searchHandler]
    );

    const populateOptions = (results) => {
        const newOptions = results || [];
        setOptions(newOptions);
        setSearching(false);
    };

    useEffect(() => {
        if (loaded && inputValue === '') {
            return;
        }
        (async () => await fetchOptions(inputValue))();
    }, [fetchOptions, inputValue, loaded]);

    const changeHandler = (_event, val, reason) => {
        if ("selectOption" === reason || "removeOption" === reason) {
            onChange(val);
        } else if ("clear" === reason) {
            onClear();
        }
    };

    return (
        <Autocomplete
            id={id}
            key={id}
            // freeSolo={true}
            handleHomeEndKeys={true}
            disabled={disabled}
            multiple={multiple}
            loading={Boolean(searching && inputValue)}
            loadingText={t("searchingPlaceholder", "Søker...")}
            getOptionLabel={(option) => option.title || ""}
            options={options}
            autoComplete={true}
            includeInputInList={true}
            filterSelectedOptions={true}
            isOptionEqualToValue={(option, val) =>
                option.uniqueId !== undefined && option.uniqueId === val.uniqueId
            }
            ChipProps={{color: "secondary"}}
            value={value}
            openOnFocus={true}
            onChange={changeHandler}
            onInputChange={(_event, _newInputValue) => {
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    size={size}
                    helperText={helperText}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <Box component={"li"} {...props}>
                        <If boolean={option.create}>
                            <AddIcon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    marginRight: theme.spacing(2),
                                }}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {option.create ? t("createNew", "Opprett") : option.source}
                            </Typography>
                        </If>
                        <If boolean={!option.create}>
                            <Icon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    marginRight: theme.spacing(2),
                                }}
                            />
                            {option.title}
                        </If>
                    </Box>
                );
            }}
        />
    );
};
