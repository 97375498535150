import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";

/**
 * Fetches related documents from the DAMS API.
 *
 * @param {string[]} docUniqueIds - Unique IDs of the related documents
 *
 * @returns {Promise<object[]>} An array of objects with the following properties:
 * - `uniqueId`: The unique ID of a related document
 * - `relationType`: The relation type of a related document
 * - `relationDescription`: The relation description of a related document
 */
export const fetchRelationDocs = async (docUniqueIds) => {
    try {
        const searchString = `unique_id: (${docUniqueIds.join(' OR ')})`;
        const searchParams = new URLSearchParams(decamelizeKeysDeep({
            q: searchString,
            sort: 'created_at desc',
            expand: true,
            start: 0,
            rows: 100,
            documentType: '(relation)',
            onlyMine: false
        }));
        const res = await damsFetch(`/search?${searchParams.toString()}`);
        const {models} = res;
        return models.map(m => {
            return {
                uniqueId: m.uniqueId,
                relationType: m.content.relationType,
                relationDescription: m.content.relationDescription
            }
        });
    } catch (error) {
        clientLog('error', error.toString(), 'fetchrelationdocs');
        return [];
    }
};