import {useEffect, useState} from "react";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {DOCUMENT_SAVE_ERROR, DOCUMENT_SAVED, useDocumentDispatch,} from "./documentContext";
import {damsFetch} from "../app/damsFetch";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {PromisePool} from "@supercharge/promise-pool";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

/**
 * Hook used to save a document object.
 * @returns {[{failedDocuments: number, pendingDocuments: number},(function(*): *)]}
 */
export const usePostDocuments = () => {

    const componentName = 'usePostDocuments';

    const [pending, setPending] = useState(0);
    const [failed, setFailed] = useState(0);
    const dispatch = useDocumentDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    /**
     * Hook used to display an error message, if saving of a document fails.
     */
    useEffect(() => {
        if (0 === pending && failed > 0) {
            showSnackbar(snackbarDispatch,
                t("saveDocumentsFailTitle", "Feil ved lagring av dokumenter"),
                t("saveDocumentsFailBody", "{{count}} dokumenter ble ikke lagret", {count: failed}),
                "error");
        }
    }, [pending, failed, snackbarDispatch, t]);

    /**
     * Triggered when the document was successfully saved.
     * @param json
     * @returns {Promise<unknown>}
     */
    const successCallback = json => {
        dispatch({
            type: DOCUMENT_SAVED,
            document: json,
        });
        setPending((p) => p - 1);
        return Promise.resolve(json);
    };

    const getTitleFromDocument = document => {
        const {content} = document;
        if (!content) {
            return '';
        }
        const {mediae} = content;
        if (!mediae || mediae.length === 0) {
            return '';
        }
        const {reference} = mediae[0];
        if (!reference) {
            return '';
        }
        const {title} = reference;
        if (!title) {
            return '';
        }
        return title;
    };

    /**
     * Triggered when the document save failed.
     * @param error
     * @param document
     * @returns {Promise<never>}
     */
    const errorCallback = (error, document) => {
        setPending((p) => p - 1);
        setFailed((f) => f + 1);
        clientLog('error', error, componentName);
        const documentTitle = getTitleFromDocument(document);
        dispatch({
            type: DOCUMENT_SAVE_ERROR,
            fileName: documentTitle
        });
        return Promise.reject(error);
    };

    const postDocuments = (documents) => {
        setPending(documents.length);
        setFailed(0);
        return documents.map((document) => {
            return damsFetch(`/documents`, {
                method: "POST",
                body: JSON.stringify(decamelizeKeysDeep(document)),
            })
                .then(successCallback)
                .catch(error => errorCallback(error, document));
        });
    };


    const postDocumentsInChunks = async (documents) => {
        return await PromisePool
            .withConcurrency(2)
            .for(documents)
            .process(async (document) => {
                try {
                    const postedDoc = await damsFetch('/documents', {
                        method: 'POST',
                        body: JSON.stringify(decamelizeKeysDeep(document))
                    });
                    dispatch({
                        type: DOCUMENT_SAVED,
                        document: postedDoc
                    });
                    setPending(p => p - 1)
                    return postedDoc;
                } catch (e) {
                    setPending(p => p - 1);
                    setFailed(f => f + 1);
                    dispatch({
                        type: DOCUMENT_SAVE_ERROR,
                        fileName: document.content.mediae[0].reference.title
                    });
                    clientLog('error', e, componentName);
                }
            });
    };

    return [
        {pendingDocuments: pending, failedDocuments: failed},
        postDocuments,
        postDocumentsInChunks
    ];
};
