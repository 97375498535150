import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "./ProgressIndicator";
import {WithAuthentication} from "../auths/WithAuthentication";
import {AuthsProvider} from "../auths/authsContext";
import {HeaderProvider} from "@ekultur/header-microfrontend";
import {MuseumProvider} from "../museum/MuseumContext";
import {DmsStatusProvider} from "../dmsstatus/DmsStatusContext";
import {clientLog} from "../clientLog";

export const MUSEUMS_RECEIVED = "museumsReceived";
export const APPS_RECEIVED = "appsReceived";
export const RENDERED = "rendered";
export const MENU_OPENED = "menuOpened";
export const MENU_CLOSED = "menuClosed";
export const SET_EXTERNAL_USER = 'setExternalUser';
export const SET_MUSEUM_USER_OR_ADMIN = 'setMuseumUserOrAdmin';
export const ADMIN_LOCAL_ASSETS = 'adminLocalAssets';
export const RESET_ADMIN_LOCAL_ASSETS = 'resetAdminLocalAssets';
export const OPEN_AB_TEST_DIALOG = 'openABTestDialog';
export const CLOSE_AB_TEST_DIALOG = 'closeABTestDialog';

// Operations
export const ADD_OPERATION = "addOperation";
export const UPDATE_OPERATION = "updateOperation";
export const CANCEL_OPERATION = "cancelOperation";
export const NOTIFY_FROM_WORKER = "notifyFromWorker";
export const NOTIFY_MULTIPLE_FROM_WORKER = 'notifyMultipleFromWorker';
export const FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG = 'fileUploadOperationAllFilesDialog';
export const FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG = 'fileUploadOperationSelectFieldsDialog';
export const FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG = 'fileUploadOperationEditMetadataDialog';
export const UPLOAD_WORKER_UNKNOWN_ERROR = 'uploadWorkerUnknownError';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

/**
 * Removes the specified operation, from the list of operations.
 * @param state
 * @param operation
 * @returns {*[]}
 */
const removeOperation = (state, operation) => {
    let ops = [...state.operations];
    const ix = ops.findIndex(o => o.jobId === operation.jobId);
    if (ix === -1) {
        return ops;
    }
    return ops.toSpliced(ix, 1)
};

/**
 * Updates the specified operation with the specified data.
 * @param state
 * @param data
 * @returns {[]|Operation[]|*|*[]}
 */
const updateOperation = (state, data) => {
    let ops = [...state.operations];
    const ix = ops.findIndex(o => o.jobId === data.jobId);
    if (ix === -1) {
        return state.operations;
    }
    const allOperations = [...state.operations];
    allOperations[ix] = {...allOperations[ix], ...data};
    return allOperations;
};

/**
 * Verifies that the message received from the worker, is on the right format.
 *
 * Example:
 * {
 *  "id": <short string, identifying the "stage">,
 *  "details": <an object describing the message details>
 * }
 *
 * @param message
 * @returns {boolean}
 */
const validWorkerMessage = message => {
    const validKeys = ['id', 'details'];
    const keys = Object.keys(message);
    if (keys.length === validKeys.length) {
        keys.forEach(k => {
            if (!validKeys.includes(k)) {
                return false;
            }
        })
    } else {
        return false;
    }
    return true;
};

/**
 * Updates the specified operation in the list of operations, when a message is received from the worker,
 * and returns an updated state.
 * @param state
 * @param action
 * @returns {*|(*&{operations: *[]})}
 */
const setOperationCurrentState = (state, action) => {
    const {operationId, message} = action;

    if (!validWorkerMessage(message)) {
        clientLog('warn', 'worker message received, but incorrect format', 'fileupload');
        return state;
    }

    const operations = [...state.operations];

    if (operationId === null) {
        // If no operationID is provided, operations are resurrected - they all need to be updated.
        operations.forEach(o => o['lastUpdated'] = new Date().getTime());
    } else {
        const ix = state.operations.findIndex(o => o.jobId === operationId);

        if (ix === -1) {
            clientLog('warn', 'notifying operation not found', 'fileupload');
        }

        const operation = operations[ix];
        if (!operation['workerMessages']) {
            operation['workerMessages'] = [];
        }
        operation['workerMessages'].push(message);
        operation['lastUpdated'] = new Date().getTime();
        operations[ix] = operation;

        clientLog('debug', 'operations updated: ' + JSON.stringify(message), 'fileupload');
    }

    return {
        ...state,
        operations: operations
    }
};

/**
 * Updates the state of multiple operations based on the provided messages.
 *
 * NOTE: The received message are stored under the operation.workerMessages array.
 *
 * @param {object} state - The current state of the application.
 * @param {object} data - An object containing the count and messages to update the operations with.
 * @return {object} The updated state with the new operation messages.
 */
const setOperationsCurrentState = (state, data) => {
    const {count, data: messages} = data;

    if (count === 0 || (messages.length === 0)) {
        return state;
    }

    const allOperations = [...state.operations];
    messages.forEach(m => {
        const ix = allOperations.findIndex(o => o.jobId === m.operationId);
        if (ix === -1) {
            clientLog('warn', 'notifying operation not found', 'fileupload');
        } else {
            const operation = allOperations[ix];
            if (!operation['workerMessages']) {
                operation['workerMessages'] = [];
            }

            // Figure out if current message already exists, if not, else replace it!
            const wix = operation['workerMessages'].findIndex(wm => wm.id === m.message.id);
            if (wix === -1) {
                operation['workerMessages'].push(m.message);
            } else if (wix > -1) {
                operation['workerMessages'][wix] = m.message;
            }

            // Always update the timestamp.
            operation['lastUpdated'] = new Date().getTime();
            allOperations[ix] = operation;

            clientLog('debug', 'operation worker messages updated: ' + JSON.stringify(messages), 'fileupload');
        }
    });
    return {
        ...state,
        operations: allOperations
    }
};

const initialState = {
    museums: [],
    apps: [],
    operations: [],
    menuOpen: false,
    rendered: false,
    externalUser: false,
    museumUserOrAdmin: false,
    adminLocalAssets: {
        museum: undefined,
        assetType: undefined
    },
    fileUploadOperationAllFilesDialog: {
        open: true,
        operationId: null
    },
    fileUploadOperationEditFiles: {
        open: false,
        operationId: null,
        documentIds: [],
    },
    openSelectFieldsDialog: false,
    uploadWorkerUnknownError: false,
    openAbTestDialog: false
};

const appReducer = (state, action) => {
    switch (action.type) {
        case OPEN_AB_TEST_DIALOG:
            return {
                ...state,
                openAbTestDialog: true
            };
        case CLOSE_AB_TEST_DIALOG:
            return {
                ...state,
                openAbTestDialog: false
            };
        case UPLOAD_WORKER_UNKNOWN_ERROR:
            return {
                ...state,
                uploadWorkerUnknownError: true
            };
        case RESET_ADMIN_LOCAL_ASSETS:
            return {
                ...state,
                adminLocalAssets: {
                    museum: undefined,
                    assetType: undefined
                }
            };
        case ADMIN_LOCAL_ASSETS:
            return {
                ...state,
                adminLocalAssets: {
                    museum: action.museum || state.adminLocalAssets.museum,
                    assetType: action.assetType || state.adminLocalAssets.assetType
                }
            };
        case SET_MUSEUM_USER_OR_ADMIN:
            return {
                ...state,
                museumUserOrAdmin: action.value
            };
        case SET_EXTERNAL_USER:
            return {
                ...state,
                externalUser: true
            };
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums,
            };
        case APPS_RECEIVED:
            return {
                ...state,
                apps: action.apps,
            };
        case RENDERED:
            return {
                ...state,
                rendered: true,
            };
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true,
            };
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false,
            };
        case ADD_OPERATION:
            return {
                ...state,
                operations: [...state.operations, action.operation]
            };
        case UPDATE_OPERATION:
            return {
                ...state,
                operations: updateOperation(state, action.operation)
            };
        case CANCEL_OPERATION:
            return {
                ...state,
                operations: removeOperation(state, action.operation)
            };
        case NOTIFY_FROM_WORKER:
            return setOperationCurrentState(state, action);
        case NOTIFY_MULTIPLE_FROM_WORKER:
            return setOperationsCurrentState(state, action.messages);
        case FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG:
            return {
                ...state,
                fileUploadOperationAllFilesDialog: action.data,
            };
        case FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG: {
            return {
                ...state,
                openSelectFieldsDialog: action.open
            }
        }
        case FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG: {
            return {
                ...state,
                fileUploadOperationEditFiles: {
                    operationId: action.operationId,
                    documentIds: action.documentIds,
                    open: action.open
                }
            };
        }
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        appReducer,
        {...initialState},
        undefined
    );
    const {t, ready} = useTranslation("damsApp", {
        useSuspense: false,
    });

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppTranslationContext.Provider value={t}>
                    <HeaderProvider>
                        <AuthsProvider>
                            <WithAuthentication>
                                <DmsStatusProvider>
                                    <MuseumProvider>{children}</MuseumProvider>
                                </DmsStatusProvider>
                            </WithAuthentication>
                        </AuthsProvider>
                    </HeaderProvider>
                </AppTranslationContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
};

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
};

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider");
    } else {
        return context;
    }
};
