import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDocumentTranslation} from "../documents/documentContext";
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {ReferenceTypeAutoComplete} from "./ReferenceTypeAutoComplete";
import {useField, useFormikContext} from "formik";

/**
 * A form field for entering references.
 *
 * The component renders a list of references, each consisting of a reference type
 * (selected from a list of possible types), a description and a URL. The user can
 * add new references and remove existing ones.
 *
 * The component uses the `useDocumentTranslation` hook to get the translations
 * for the labels and the `useState` hook to store the references.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ReferenceTypeField = () => {

    const formikKey = 'referenceTypes';

    const {setFieldValue} = useFormikContext();

    const [field] = useField(formikKey);

    const emptyRefObject = {refGuid: crypto.randomUUID(), refType: {}, refDescription: '', refUrl: ''};

    const [references, setReferences] = useState(field.value || []);

    const t = useDocumentTranslation();

    /**
     * Adds a new reference to the list of references.
     *
     * The new reference will have a unique `refGuid` and empty
     * `refType`, `refDescription` and `refUrl` properties.
     */
    const addReference = () => {
        setReferences([...references, emptyRefObject]);
    };

    /**
     * Removes a reference from the list of references.
     *
     * @param {string} guid - The GUID of the reference to remove.
     */
    const removeReference = (guid) => {
        const remaining = references.filter(r => r.refGuid !== guid);
        if (remaining.length === 0) {
            setFieldValue(field.name, []).then(() => {
                setReferences([]);
            });
        } else {
            setReferences(remaining);
        }
    };

    /**
     * Updates the reference type for a given reference.
     *
     * @param {string} guid - The GUID of the reference to update.
     * @param {Object} value - The new reference type. The object should have a `sourceId` and a `title` property.
     */
    const setReferenceType = (guid, value) => {
        const refs = [...references];
        refs.find(r => r.refGuid === guid).refType = value;
        setReferences(refs);
    };

    /**
     * Handles changes to the reference description.
     *
     * Updates the `refDescription` property of the reference with the given `guid`
     * to the given `value`.
     *
     * @param {string} guid - The GUID of the reference to update.
     * @param {string} value - The new value for the reference description.
     */
    const handleReferenceDescriptionChange = (guid, value) => {
        const refs = [...references];
        refs.find(r => r.refGuid === guid).refDescription = value;
        setReferences(refs);
    };

    /**
     * Handles changes to the reference URL.
     *
     * Updates the `refUrl` property of the reference with the given `guid`
     * to the given `value`.
     *
     * @param {string} guid - The GUID of the reference to update.
     * @param {string} value - The new value for the reference URL.
     */
    const handleReferenceUrlChange = (guid, value) => {
        const refs = [...references];
        refs.find(r => r.refGuid === guid).refUrl = value;
        setReferences(refs);
    };

    /**
     * Hook used to update the references in the form.
     */
    useEffect(() => {
        (async () => {
            if (references.length > 0 && Object.keys(references[0].refType).length > 0 && field.value.length === 0) {
                // Clear all reference type fields, when the form is reset.
                setReferences([]);
            } else if (references.length === 0 && field.value.length > 0) {
                setReferences(field.value);
            } else {
                await setFieldValue(formikKey, references)
            }
        })();
    }, [references, setFieldValue, field.value]);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
    }}>
        <Typography variant={"h6"}>
            {t('txtReference', 'Referanse')}
        </Typography>
        {references.map(r => (
            <Box key={`container-${r.refGuid}`}
                 sx={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     marginTop: '8px',
                     marginBottom: '8px',
                     alignContent: 'center',
                     padding: '16px',
                     border: 'solid 1px #ddd',
                     borderRadius: '3px'
                 }}>
                <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <ReferenceTypeAutoComplete callback={setReferenceType} guid={r.refGuid}
                                               value={r.refType}/>
                    <TextField id={"txt-reference-description"}
                               label={t('txtReferenceDescription', 'Referansebeskrivelse')}
                               variant={"outlined"}
                               multiline={true}
                               fullWidth={true}
                               rows={2}
                               value={r.refDescription}
                               onChange={(event) => handleReferenceDescriptionChange(r.refGuid, event.target.value)}
                    />
                    <TextField id={"txt-reference-url"}
                               label={t('txtReferenceUrl', 'Referanse-URL')}
                               variant={"outlined"}
                               fullWidth={true}
                               value={r.refUrl}
                               onChange={(event) => handleReferenceUrlChange(r.refGuid, event.target.value)}
                    />
                </Box>
                <Divider orientation={"vertical"} flexItem={true}
                         sx={{width: '3px', marginLeft: 1, marginRight: 1}}/>
                <IconButton
                    sx={{maxHeight: '40px'}}
                    key={`btn-delete-${r.refGuid}`}
                    size={"large"}
                    variant={"contained"}
                    onClick={() => removeReference(r.refGuid)}
                >
                    <DeleteIcon/>
                </IconButton>
            </Box>))
        }
        <Button sx={{alignSelf: 'flex-start', marginTop: '8px', marginBottom: '16px'}}
                color={"secondary"}
                onClick={addReference}>+ {t('btnAdd', 'Legg til')}</Button>
    </Box>;
};