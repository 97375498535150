import {useEffect, useState} from "react";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {DOCUMENT_SAVE_ERROR, DOCUMENT_SAVED, useDocumentDispatch} from "../documents/documentContext";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";


/**
 * Hook used to post documents were the reference to the specified folder has been removed.
 * @returns {[{failedDocuments: number, pendingDocuments: number},(function(*): *)]}
 */
export const useRemoveFromFolder = () => {
    const [pending, setPending] = useState(0);
    const [failed, setFailed] = useState(0);
    const dispatch = useDocumentDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    /**
     * Hook used to display an error dialog if something has failed.
     */
    useEffect(() => {
        if (0 === pending && failed > 0) {
            showSnackbar(snackbarDispatch,
                t("saveDocumentsFailTitle", "Feil ved lagring av dokumenter"),
                t("saveDocumentsFailBody", "{{count}} dokumenter ble ikke lagret", {count: failed}),
                "error");
        }
    }, [pending, failed, snackbarDispatch, t]);


    /**
     * Removes references to the current folder ID from the document objects.
     * This is the same as deleting the documents from the folder.
     */
    const removeFolderIdFromDocumentObjects = (documents, folderId) => {
        for (let i = 0, max = documents.length; i < max; i++) {
            const document = documents[i];
            document.folderIds = document.folderIds.filter(
                (id) => parseInt(id) !== parseInt(folderId)
            );
        }
        return documents;
    };

    /**
     * Callback triggered when the document was successfully removed from the folder.
     * @param json
     * @returns {Promise<unknown>}
     */
    const successCallback = json => {
        dispatch({
            type: DOCUMENT_SAVED,
            document: json,
        });
        setPending((p) => p - 1);
        return Promise.resolve(json);
    };

    /**
     * Callback triggered if something failed when processing the document.
     * @param error
     * @returns {Promise<never>}
     */
    const errorCallback = error => {
        setPending((p) => p - 1);
        setFailed((f) => f + 1);
        dispatch({
            type: DOCUMENT_SAVE_ERROR,
            fileName: document.content.mediae[0].reference.title,
        });
        clientLog('error', error, 'useRemoveFromFolder');
        return Promise.reject(error);
    };


    /**
     * Saves the specified documents to the DAMS database, but removes the folder with ID `folderId` from the document.
     * @param {object[]} documents The documents to save.
     * @param {number} folderId The ID of the folder to remove from the document.
     * @returns {Promise<object[]>} A promise that resolves to an array of the saved documents.
     */
    const postDocuments = (documents, folderId) => {
        setPending(documents.length);
        setFailed(0);

        documents = removeFolderIdFromDocumentObjects(documents, folderId);

        return documents.map((document) => {
            return damsFetch(`/documents`, {
                method: "POST",
                body: JSON.stringify(decamelizeKeysDeep(document)),
            })
                .then(successCallback)
                .catch(errorCallback);
        });
    };

    return [
        {pendingDocuments: pending, failedDocuments: failed},
        postDocuments,
    ];
};
