import {getFolderSearchParams} from "../../getFolderSearchParams";
import {damsFetch} from "../../app/damsFetch";

/**
 * Async method used to fetch the list of folders.
 * @param {string} query - The search query.
 * @param {string} sort - The sorting criteria.
 * @param {string} fl - The field list to display.
 * @param {number} start - The starting index for search results.
 * @param {number} rows - The number of rows to fetch.
 * @param {array} fq - The filter queries.
 * @param {string} status - The status of the folders.
 * @param {array} museums - The list of museums.
 * @param {boolean} privateOnly - Flag to search only private folders.
 * @param {boolean} onlyMine - Flag to search only user's folders.
 * @param {boolean} onlyOthers - Flag to search others' folders.
 * @param {boolean} expand - Flag to expand search.
 * @param {string} folderType - The type of folder to search.
 * @returns {Promise<{count: number, models: array, facets: {tags: object, createdByName: object}, stats: {createdAt: object, updatedAt: object, productionDate: object, createdByName: object}}>} Promise resolving to an object containing the count of search results, the models (the folders), facets and stats.
 */
export const getFolders = async ({
                                     query,
                                     sort,
                                     fl,
                                     start = 0,
                                     rows = 100,
                                     fq = [],
                                     status = '(-trash)',
                                     museums = [],
                                     privateOnly,
                                     onlyMine = false,
                                     onlyOthers = false,
                                     expand = false,
                                     folderType = ''
                                 }) => {

    const getFacet = facet => {
        if (!facet) {
            return 0;
        }

        let counter = {};
        for (let i = 0; i < facet.length; i += 2) {
            counter[facet[i]] = {
                count: facet[i + 1],
            };
        }
        return counter;
    };

    const getStats = (stats) => ({
        min: stats.min,
        max: stats.max,
    });

    const params = {
        q: (!query || query === '') ? '*:*' : query,
        sort: sort,
        fl: fl,
        expand: expand,
        start: start,
        rows: rows,
        documentType: `(Folder)`,
        museums: museums,
        onlyMine: onlyMine,
        onlyOthers: onlyOthers,
        status: privateOnly ? '(-trash private)' : status,
        facetField: "tags.reference.title,created_by_name", // Add additional filter fields here!
        facetLimit: 100,
        statsField: "created_at,updated_at,production_date,created_by_name",
    };

    const searchParams = getFolderSearchParams(folderType, params, fq);

    const json = await damsFetch(`/folders/search?${searchParams.toString()}`);
    if (!json) {
        return [];
    }

    const {facetFields, statsFields, count, models} = json;

    return {
        count,
        models,
        facets: {
            tags: getFacet(facetFields['tagsReferenceTitle']),
            createdByName: getFacet(facetFields['createdByName'])
        },
        stats: {
            createdAt: getStats(statsFields['createdAt']),
            updatedAt: getStats(statsFields['updatedAt']),
            productionDate: getStats(statsFields['productionDate']),
            createdByName: getFacet(statsFields['createdByName']),
        }
    };
};